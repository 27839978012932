import { get } from 'lodash';
import {
  getWixDataCategoryId,
  getWixDataTagId,
  handleAggregatorResponseWithHeaders,
  POST_LIST_SETTINGS_PARAMS,
  isExperimentEnabled,
} from '@wix/communities-blog-client-common';
import {
  EXPERIMENT_MOVE_LOGIC_TO_POST_LIST_ADAPTER,
  EXPERIMENT_OOI_POSTS_WITH_SITE_TIMEZONE,
} from '@wix/communities-blog-experiments';
import { getInstanceId } from '../../common/store/instance-values/instance-values-selectors';
import { getPostListWidgetPageSize } from '../selectors/post-list-widget-page-size';
import { getAppSettingsValue } from '../../common/selectors/app-settings-base-selectors';
import { fetchTopology } from '../../common/store/topology/topology-actions';
import { handleTranslationsResponse } from '../../common/store/translations/translations-actions';
import { getQueryLocale } from '../../common/selectors/locale-selectors';
import { handleCategoriesResponse } from '../../common/store/categories/fetch-categories';
import { getIsICUBaseUrlEnabled } from '../../common/selectors/icu-selectors';
import { getIsICUEnabled } from '../../common/store/basic-params/basic-params-selectors';
import { createPromisifiedAction } from '../../common/actions-promisifier/create-promisified-action';
import { getTotalResults } from '../../common/services/pagination';
import setPosts from '../../common/actions/set-posts';
import { setIsLoading } from '../../common/store/is-loading/is-loading-actions';
import { setPostCount } from '../../common/store/post-count/set-posts-count';
import timezoneService from '../../common/services/timezone';

const fetchPostListRenderModel = ({
  aggregatorRequest,
  state,
  isCategoryLabelEnabled,
  page,
  useICU,
  fields,
}) => {
  const moveLogicToPostListAdapter = isExperimentEnabled(
    state,
    EXPERIMENT_MOVE_LOGIC_TO_POST_LIST_ADAPTER,
  );

  const isPostWithSiteTimezoneEnabled = isExperimentEnabled(
    state,
    EXPERIMENT_OOI_POSTS_WITH_SITE_TIMEZONE,
  );
  const postLimit = getPostListWidgetPageSize(state);
  const featuredOnly = getAppSettingsValue({
    state,
    key: POST_LIST_SETTINGS_PARAMS.isFeatured.appSettingsPath,
  });
  const categoryId = getWixDataCategoryId(state);
  const tagId = getWixDataTagId(state);
  const language = getQueryLocale(state);

  return aggregatorRequest(
    `/v1/post-list-widget/render-model?${[
      postLimit && `postLimit=${postLimit}`,
      featuredOnly && `featuredOnly=${featuredOnly}`,
      categoryId && `categoryId=${categoryId}`,
      tagId && `tagId=${tagId}`,
      language && `language=${language}`,
      page && `page=${page}`,
      moveLogicToPostListAdapter && 'moveLogicToPostListAdapter=true',
      isCategoryLabelEnabled && 'fetchCategories=true',
      useICU && 'icu=true',
      isPostWithSiteTimezoneEnabled && `timezone=${timezoneService}`,
      fields && `fields=${fields.join(',')}`,
    ]
      .filter(Boolean)
      .join('&')}`,
    { throwOnInvalidJson: true },
  );
};

const handlePostListPostsResponse = (postsResponse) => (dispatch) => {
  dispatch(setIsLoading('postListPosts', undefined, true));

  return dispatch(handleAggregatorResponseWithHeaders(postsResponse))
    .then(({ body, headers }) => {
      dispatch(setPosts(body));
      dispatch(setPostCount(getTotalResults(headers)));
    })
    .then(() => dispatch(setIsLoading('postListPosts', undefined, false)))
    .catch(() => dispatch(setIsLoading('postListPosts', undefined, false)));
};

export const fetchInitialData = () => async (
  dispatch,
  getState,
  { aggregatorRequest, appParams },
) => {
  const state = getState();
  const instanceId = getInstanceId(state);
  const isCategoryLabelEnabled =
    get(appParams, 'baseUrls.categoryLabel') === 'true';
  const useICU = getIsICUBaseUrlEnabled(appParams);

  const { translations, posts, categories } = await fetchPostListRenderModel({
    aggregatorRequest,
    state,
    appParams,
    isCategoryLabelEnabled,
    useICU,
  });

  await dispatch(handleTranslationsResponse(translations));
  if (categories) {
    await dispatch(handleCategoriesResponse(categories));
  }
  await dispatch(fetchTopology(instanceId));
  await dispatch(handlePostListPostsResponse(posts));
};

export const fetchPostListPosts = (page) => async (
  dispatch,
  getState,
  { aggregatorRequest, appParams },
) => {
  const state = getState();
  const useICU = getIsICUEnabled(state);

  const { posts } = await fetchPostListRenderModel({
    aggregatorRequest,
    state,
    appParams,
    page,
    fields: ['posts'],
    useICU,
  });
  await dispatch(handlePostListPostsResponse(posts));
};

export const fetchPostListPostsPromisified = createPromisifiedAction(
  fetchPostListPosts,
  () => null,
  (response) => response.status,
);
